import React from 'react';
import ErrorLayout from './ErrorLayout';

const NotFound = () => {
  return (
    <ErrorLayout
      title="페이지를 찾을 수 없습니다."
      text="요청하신 주소가 잘못되었거나<br/>
  변경 또는 삭제되었을 수 있습니다.<br/>
  주소를 다시 한번 확인 부탁드립니다."
    ></ErrorLayout>
  );
};

export default NotFound;
