import React from 'react';
import { NotFound } from '@/feat-components/error';
import Unavailable from '@/feat-components/error/Unavailable';
import { NextPageContext } from 'next';
import UnusualApproach from '@/feat-components/error/UnusualApproach';
import * as Sentry from '@sentry/nextjs';

interface ErrorPagePropsType {
  statusCode: number;
}

const RootError = ({ statusCode }: ErrorPagePropsType) => {
  if (statusCode === 404) return <NotFound />;
  else if (statusCode === 500) return <Unavailable />;
  return <UnusualApproach />;
};

RootError.getInitialProps = async ({ res, err }: NextPageContext) => {
  await Sentry.captureUnderscoreErrorException({ res, err });
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

export default RootError;
