import React, { useCallback } from 'react';
import { useRouter } from 'next/router';
import TrackingService from '@/services/tracking';
import { NewBasicButton, Text, VStack } from '@design-system/pc';

export interface UnavailableProps {
  // pages/error 에서 접근시
  isPageError?: boolean;
  hasRefresh?: boolean;
  hasPrevious?: boolean;
}

const Unavailable = ({
  isPageError,
  hasRefresh = true,
  hasPrevious = true,
}: UnavailableProps) => {
  const router = useRouter();
  const handleRefresh = useCallback(() => {
    router.reload();
    isPageError && router.back();
  }, [isPageError, router]);

  const handleBack = useCallback(() => {
    router.back();
  }, [router]);

  return (
    <VStack
      width="100%"
      padding="0 16px"
      {...TrackingService.tagGTM('unavailable')}
    >
      <VStack space={12} mt="76px">
        <Text
          as="p"
          variant="subTitle2"
          color="color_chip.primary"
          textAlign="center"
        >
          서비스에 접속할 수 없습니다.
        </Text>
        <Text
          as="p"
          variant="body2"
          color="color_chip.primary"
          textAlign="center"
        >
          이용에 불편을 드려 죄송합니다.
          <br /> 잠시 후 다시 이용 부탁드립니다.
        </Text>
      </VStack>
      {(hasRefresh || hasPrevious) && (
        <VStack mt="40px" space={8} width="100%">
          {hasRefresh && (
            <NewBasicButton
              size="lg"
              emphasis="medium"
              width="100%"
              onClick={() => handleRefresh()}
              {...TrackingService.tagGTM('unavailable_refresh_button')}
            >
              새로 고침
            </NewBasicButton>
          )}
          {hasPrevious && (
            <NewBasicButton
              size="lg"
              emphasis="medium"
              width="100%"
              onClick={() => handleBack()}
              {...TrackingService.tagGTM('unavailable_go_previous_button')}
            >
              이전 페이지
            </NewBasicButton>
          )}
        </VStack>
      )}
    </VStack>
  );
};

export default Unavailable;
