import { theme } from '@/styles';
import ContentsButton from '@/ui/ContentsButton';
import Image from '@/ui/Image';
import { getAsset } from '@/utils';
import { CI } from '@design-system/pc';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import styled from 'styled-components';

interface ErrorLayoutProps {
  title: string;
  text: string;
}

const ErrorLayout = (props: ErrorLayoutProps) => {
  const router = useRouter();
  return (
    <ErrorContainer>
      <LogoIconBox>
        <CI variant="CI_symbol_primary" resize width={121} height={30} />
      </LogoIconBox>
      <BangIconBox>
        <Image
          src={getAsset('/assets/icons/ic_empty_72.svg')}
          width="72px"
          height="72px"
          alt="errorBangIcon"
        />
      </BangIconBox>
      <ErrorTitle>{props.title}</ErrorTitle>
      <ErrorText dangerouslySetInnerHTML={{ __html: props.text }}></ErrorText>
      <BtnLayout>
        <Link href={'/'}>
          <ContentsButton size="bold" emphasis="high" width="100%">
            한샘몰 메인
          </ContentsButton>
        </Link>

        <ContentsButton
          size="bold"
          emphasis="low"
          width="100%"
          onClick={() => router.back()}
        >
          이전 페이지
        </ContentsButton>
      </BtnLayout>
    </ErrorContainer>
  );
};

export default ErrorLayout;

const ErrorContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  padding-top: 160px;
`;

const LogoIconBox = styled.div`
  margin-bottom: 125px;
`;

const BangIconBox = styled.div`
  margin-bottom: 24px;
`;

const ErrorTitle = styled.div`
  color: ${theme.colors.accent_colors.primary.default};
  margin-bottom: 40px;
  ${theme.typography.subtitle2};
`;

const ErrorText = styled.div`
  white-space: pre-wrap;
  text-align: center;
  color: ${theme.colors.neutral_colors.gray[700]};
  margin-bottom: 80px;
  ${theme.typography.body1};
`;

const BtnLayout = styled.div`
  display: flex;
  max-width: 570px;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;
