import React from 'react';
import ErrorLayout from './ErrorLayout';

const UnusualApproach = () => {
  return (
    <ErrorLayout
      title="정상적인 접근이 아닙니다."
      text="이용에 불편을 드려 죄송합니다.<br/>
      더 좋은 서비스 제공을 위해 최선을 다하겠습니다.<br/>
      감사합니다.
          "
    ></ErrorLayout>
  );
};

export default UnusualApproach;
